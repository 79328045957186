import * as React from "react";
import { useDetectAdBlock } from "adblock-detect-react";
import { Helmet } from "react-helmet";
import styled, { ThemeProvider } from "styled-components";
import { Reset } from "styled-reset";
import { useIntl } from "gatsby-plugin-intl";

import { ENGLISH_HOMEPAGE, FRENCH_HOMEPAGE } from ".";
import { getPageTitle } from "../Locales";
import { theme } from "../theme/theme";
import FontFaces from "../theme/fonts/font-faces";
import GlobalStyle from "../theme/global";
import { Header } from "../components/header";
import { Footer } from "../components/footer";
import { HalfCenteredRow } from "../components/common/layout";

const queryString = require("query-string");

const ConfirmationPage = () => {
  const adBlockDetected = useDetectAdBlock();
  const intl = useIntl();
  React.useEffect(() => {
    if (!adBlockDetected) {
      const pathParameters = queryString.parse(window.location.search);
      const personalizedAffiliateLink: string | undefined = decodeURIComponent(
        pathParameters.personalizedAffiliateLink
      );

      if (personalizedAffiliateLink)
        setTimeout(
          () => (window.location.href = personalizedAffiliateLink),
          100
        );
    }
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Reset />
      <GlobalStyle />
      <main>
        <Helmet>
          <title>
            {getPageTitle(
              intl.formatMessage({ id: "shopOnDesktop.pageTitle" })
            )}
          </title>
          <style type="text/css">{FontFaces}</style>
          <link
            rel="alternate"
            hrefLang="en-gb"
            href={ENGLISH_HOMEPAGE + "/shop-on-desktop"}
          />
          <link
            rel="alternate"
            hrefLang="en-us"
            href={ENGLISH_HOMEPAGE + "/shop-on-desktop"}
          />
          <link
            rel="alternate"
            hrefLang="en"
            href={ENGLISH_HOMEPAGE + "/shop-on-desktop"}
          />
          <link
            rel="alternate"
            hrefLang="fr"
            href={FRENCH_HOMEPAGE + "/shop-on-desktop"}
          />
          <link
            rel="alternate"
            hrefLang="x-default"
            href={ENGLISH_HOMEPAGE + "/shop-on-desktop"}
          />
          <meta
            name="description"
            content={
              intl.locale === "en"
                ? "What if all your purchases became cheaper, without any effort? Coupons, price tracking, cash back"
                : "Boostez votre pouvoir d'achat ! Et si tout devenait moins cher, sans aucun effort ? Codes promo, suivi des prix, cashback, paiement en plusieurs fois"
            }
            key="description"
          />
        </Helmet>
        <Header />
        <Wrapper>
          <HalfCenteredRow>
            {adBlockDetected ? (
              <>
                <Heading className="small-heading">
                  {intl.formatMessage({
                    id: "shopOnDesktop.adBlockerDetected.title",
                  })}
                </Heading>
                <IntroWrapper>
                  <p className="emphasized-text">
                    {intl.formatMessage({
                      id: "shopOnDesktop.adBlockerDetected.subtitle",
                    })}
                  </p>
                </IntroWrapper>
              </>
            ) : (
              <Heading className="small-heading">
                {intl.formatMessage({
                  id: "shopOnDesktop.redirection.title",
                })}
              </Heading>
            )}
          </HalfCenteredRow>
        </Wrapper>
        <Footer />
      </main>
    </ThemeProvider>
  );
};

export default ConfirmationPage;

const Wrapper = styled.section`
  min-height: 100vh;
  box-sizing: border-box;
  padding: 6.25em 0;
`;

const Heading = styled.h1`
  text-align: center;
  margin-bottom: 1em;
`;

const IntroWrapper = styled.div`
  margin-top: 3.125em;
  margin-bottom: 6.25em;
  text-align: center;
`;
